
import { defineComponent } from "vue";
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";

let isSceneLoaded = false;
let isMobile = false;
let isCameraMovementEnabled = false;

let renderer: THREE.Renderer;
let scene: THREE.Scene;

// #00FF85
const colorB1 = new THREE.Color(0, 1, 0.522);
// #5020F0
const colorB2 = new THREE.Color(0.313, 0.125, 0.941);

const manager = new THREE.LoadingManager();
manager.onLoad = function () {
  isSceneLoaded = true;
};

const obj_loader = new OBJLoader(manager);
let textureLoader = new THREE.TextureLoader(manager);

let objectMap = new Map<string, THREE.Object>();

let lastMouseX = 0,
  lastMouseY = 0,
  lastScrollTop = 0;

export default defineComponent({
  name: "InfiniteRotation",
  watch: {
    $route(to) {
      this.showSceneFromRoute(to.name);
    },
  },
  data() {
    return {
      marbleUniforms: {
        time: { value: 0 },
        colorA: { value: new THREE.Color(0, 0, 0) },
        colorB: { value: new THREE.Color(0, 0, 0) },
        heightMap: { value: null },
        displacementMap: { value: null },
        iterations: { value: 42 },
        depth: { value: 4.5 },
        smoothing: { value: 0.2 },
        displacement: { value: 1 },
      },
      marbleSettings: {
        animationSpeed: 0.015,
        radius: 8,
        widthElementsAmount: 64,
        heightElementAmount: 32,
      },
      scrollNormalized: 0,
    };
  },
  mounted() {
    this.rendererInit();
    this.afterSceneLoadingHandler();
  },
  unmounted() {
    renderer.dispose();
  },
  methods: {
    afterSceneLoadingHandler() {
      if (isSceneLoaded === false) {
        window.setTimeout(this.afterSceneLoadingHandler, 50);
      } else {
        this.onSceneLoaded();
      }
    },

    onSceneLoaded() {
      this.showSceneFromRoute(this.$route.name);
      document.body.classList.add("loaded-scene");
    },

    async rendererInit() {
      const container = document.getElementById("rendererInit");
      if (!container) return;
      // @ts-expect-error: TS is not aware of isMobile() method of parent during compilation
      if (this.$parent.isMobile()) isMobile = true;

      let distance_reference_element = document.createElement("pos_ref");
      distance_reference_element.classList.add("mouse_pos_reference");
      document.body.appendChild(distance_reference_element);

      scene = new THREE.Scene();
      const clock = new THREE.Clock();
      let orbit_origin = new THREE.Object3D();
      scene.add(orbit_origin);
      let camera = new THREE.PerspectiveCamera(
        64,
        innerWidth / innerHeight,
        1,
        1000
      );
      orbit_origin.add(camera);
      camera.position.set(0, 0, 32);
      camera.lookAt(0, 0, 0);
      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setSize(innerWidth, innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setClearColor(0x000000, 0);
      container.appendChild(renderer.domElement);

      // x red | y green | z blue      =>     rgb = zyx
      // const axesHelper = new THREE.AxesHelper(5);
      // scene.add(axesHelper);

      const envMap: THREE.Texture = await this.loadHDRI(
        "web_3D/hdri/empty_warehouse_01_1k.hdr",
        renderer
      );
      scene.environment = envMap;
      envMap.dispose();

      this.initHands();
      this.initMarble();

      window.addEventListener("resize", onWindowResize);
      function onWindowResize() {
        if (isMobile) return;
        camera.aspect = innerWidth / innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(innerWidth, innerHeight);
      }

      let distanceCalculator = (posX, posY) => {
        return this.calculateDistancesFromPoint(
          distance_reference_element,
          posX,
          posY
        );
      };

      function cameraPointToCursor() {
        let distance = distanceCalculator(lastMouseX, lastMouseY);
        let scale = -0.2;

        orbit_origin.rotation.y = (distance.x / window.innerWidth) * scale;
        orbit_origin.rotation.x = (distance.y / window.innerHeight) * scale;
        orbit_origin.rotation.z = 0;
      }

      document.addEventListener("mousemove", function (e) {
        if (!isCameraMovementEnabled) return;
        lastMouseX = e.pageX;
        lastMouseY = e.pageY;
        cameraPointToCursor();
      });
      document.addEventListener("scroll", function () {
        lastMouseY -= lastScrollTop;
        lastScrollTop = this.documentElement.scrollTop;
        lastMouseY += lastScrollTop;
        cameraPointToCursor();
        lambdaUpdateMarbleColor();
      });
      let lambdaUpdateMarbleColor = () => {
        this.updateMarbleColor();
      };

      renderer.setAnimationLoop(() => {
        const delta = clock.getDelta();
        this.tick(clock.elapsedTime, delta);
        renderer.render(scene, camera);
      });
    },

    tick(time, delta) {
      this.marbleUniforms.time.value +=
        delta * this.marbleSettings.animationSpeed;
    },

    initHands() {
      // Marble Hand
      obj_loader.load(
        "web_3D/models/hand.obj",
        // called when resource is loaded
        function (hand_obj) {
          hand_obj.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              const material_marble = new THREE.MeshStandardMaterial({
                roughness: 0.1,
                color: 0x0d0d0d,
              });

              child.material = material_marble;
            }
          });
          objectMap.set("hand", hand_obj);
          hand_obj.visible = false;
          scene.add(hand_obj);

          hand_obj.position.y = -21;
          hand_obj.position.x = 10;
          hand_obj.rotation.y = Math.PI;
          hand_obj.rotation.z = Math.PI * -0.08;

          let violet_hand_obj = hand_obj.clone();
          violet_hand_obj.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              const material_marble = new THREE.MeshStandardMaterial({
                metalness: 0.15,
                roughness: 0.45,
                color: 0x5c30f0,
              });

              child.material = material_marble;
            }
          });
          console.log(violet_hand_obj);

          objectMap.set("violet_hand", violet_hand_obj);
          violet_hand_obj.visible = false;
          scene.add(violet_hand_obj);

          violet_hand_obj.position.y = 32;
          violet_hand_obj.position.x = -10;
          violet_hand_obj.rotation.z = Math.PI * -0.08;
          violet_hand_obj.rotation.x = Math.PI;
        },
        // called when loading is in progresses
        function (xhr) {
          console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        },
        // called when loading has errors
        function (error) {
          console.log("An error happened");
          console.log(error);
        }
      );

      function setupAttributes(geometry) {
        const vectors = [
          new THREE.Vector3(1, 0, 0),
          new THREE.Vector3(0, 1, 0),
          new THREE.Vector3(0, 0, 1),
        ];

        const position = geometry.attributes.position;
        const centers = new Float32Array(position.count * 3);

        for (let i = 0, l = position.count; i < l; i++) {
          vectors[i % 3].toArray(centers, i * 3);
        }

        geometry.setAttribute("center", new THREE.BufferAttribute(centers, 3));
      }
    },

    initMarble() {
      if (isMobile) {
        this.marbleUniforms.iterations.value = 16;
      }

      const geometry = new THREE.SphereGeometry(
        this.marbleSettings.radius,
        this.marbleSettings.widthElementsAmount,
        this.marbleSettings.heightElementAmount
      );
      const sphere_material = new THREE.MeshStandardMaterial({
        roughness: 0.0,
      });
      // Load heightmap and displacement textures
      let heightMap = textureLoader.load("web_3D/materials/marble/height.jpg");
      let displacementMap = textureLoader.load(
        "web_3D/materials/marble/displacement.jpg"
      );
      displacementMap.wrapS = displacementMap.wrapT = THREE.RepeatWrapping;
      // Prevent seam introduced by THREE.LinearFilter
      heightMap.minFilter = displacementMap.minFilter = THREE.NearestFilter;

      this.marbleUniforms.heightMap.value = heightMap;
      this.marbleUniforms.displacementMap.value = displacementMap;

      sphere_material.onBeforeCompile = (shader) => {
        // Wire up local uniform references
        shader.uniforms = { ...shader.uniforms, ...this.marbleUniforms };

        // Add to top of vertex shader
        shader.vertexShader =
          /* glsl */ `
                varying vec3 v_pos;
                varying vec3 v_dir;
            ` + shader.vertexShader;

        // Assign values to varyings inside of main()
        shader.vertexShader = shader.vertexShader.replace(
          /void main\(\) {/,
          (match) =>
            match +
            /* glsl */ `
                v_dir = position - cameraPosition; // Points from camera to vertex
                v_pos = position;
                `
        );

        // Add to top of fragment shader
        shader.fragmentShader =
          /* glsl */ `
        #define FLIP vec2(1., -1.)

        uniform vec3 colorA;
        uniform vec3 colorB;
        uniform sampler2D heightMap;
        uniform sampler2D displacementMap;
        uniform int iterations;
        uniform float depth;
        uniform float smoothing;
        uniform float displacement;
        uniform float time;

        varying vec3 v_pos;
        varying vec3 v_dir;
    ` + shader.fragmentShader;

        // Add above fragment shader main() so we can access common.glsl.js
        shader.fragmentShader = shader.fragmentShader.replace(
          /void main\(\) {/,
          (match) =>
            /* glsl */ `
            /**
         * @param p - Point to displace
         * @param strength - How much the map can displace the point
         * @returns Point with scrolling displacement applied
         */
        vec3 displacePoint(vec3 p, float strength) {
            vec2 uv = equirectUv(normalize(p));
            vec2 scroll = vec2(time, 0.);
            vec3 displacementA = texture(displacementMap, uv + scroll).rgb; // Upright
                    vec3 displacementB = texture(displacementMap, uv * FLIP - scroll).rgb; // Upside down

            // Center the range to [-0.5, 0.5], note the range of their sum is [-1, 1]
            displacementA -= 0.5;
            displacementB -= 0.5;

            return p + strength * (displacementA + displacementB);
        }

                /**
            * @param rayOrigin - Point on sphere
            * @param rayDir - Normalized ray direction
            * @returns Diffuse RGB color
            */
        vec3 marchMarble(vec3 rayOrigin, vec3 rayDir) {
            float perIteration = 1. / float(iterations);
            vec3 deltaRay = rayDir * perIteration * depth;
            // Start at point of intersection and accumulate volume
            vec3 p = rayOrigin;
            float totalVolume = 0.;
            for (int i=0; i<iterations; ++i) {
            // Read heightmap from spherical direction of displaced ray position
            vec3 displaced = displacePoint(p, displacement);
            vec2 uv = equirectUv(normalize(displaced));
            float heightMapVal = texture(heightMap, uv).r;
            // Take a slice of the heightmap
            float height = length(p); // 1 at surface, 0 at core, assuming radius = 1
            float cutoff = 1. - float(i) * perIteration;
            float slice = smoothstep(cutoff, cutoff + smoothing, heightMapVal);
            // Accumulate the volume and advance the ray forward one step
            totalVolume += slice * perIteration;
            p += deltaRay;
            }
            return mix(colorA, colorB, totalVolume);
        }
        ` + match
        );

        shader.fragmentShader = shader.fragmentShader.replace(
          /vec4 diffuseColor.*;/,
          /* glsl */ `
                vec3 rayDir = normalize(v_dir);
                vec3 rayOrigin = v_pos;

                vec3 rgb = marchMarble(rayOrigin, rayDir);
                vec4 diffuseColor = vec4(rgb, 1.);
                `
        );
      };

      let marble = new THREE.Mesh(geometry, sphere_material);
      objectMap.set("marble", marble);
      marble.visible = false;
      this.updateMarbleColor();
      scene.add(marble);
    },

    showSceneFromRoute(route_name) {
      this.hideAllObjects();
      if (route_name == "home") {
        this.showHands();
      } else if (route_name == "about" && !isMobile) {
        this.showMarble();
      }
    },

    showHands() {
      this.enableCameraMovement();
      this.setObjectVisibility(objectMap.get("hand"), true);
      this.setObjectVisibility(objectMap.get("violet_hand"), true);
    },

    showMarble() {
      this.disableCameraMovement();
      this.updateMarbleColor();
      this.setObjectVisibility(objectMap.get("marble"), true);
    },

    hideAllObjects() {
      objectMap.forEach((object) => {
        this.setObjectVisibility(object, false);
      });
    },

    setObjectVisibility(object, visibility_bool) {
      if (object) {
        object.visible = visibility_bool;
      }
    },

    calculateDistancesFromPoint(elem, mouseX, mouseY) {
      var rect = elem.getBoundingClientRect();
      const distanceX = mouseX - rect.left;
      const distanceY = mouseY - rect.top;

      return { x: distanceX, y: distanceY };
    },

    updateScrollNormalized() {
      this.scrollNormalized =
        this.getVerticalScrollPercentage(document.body) / 100;
    },

    updateMarbleColor() {
      this.updateScrollNormalized();
      this.marbleUniforms.colorB.value.lerpColors(
        colorB1,
        colorB2,
        this.scrollNormalized
      );
    },

    getVerticalScrollPercentage(elem) {
      var p = elem.parentNode;
      let relativeHeight = p.scrollHeight - p.clientHeight;

      if (relativeHeight > 0) {
        return ((elem.scrollTop || p.scrollTop) / relativeHeight) * 100;
      }
      return 0;
    },

    loadHDRI(url, renderer) {
      return new Promise((resolve) => {
        const loader = new RGBELoader();
        const pmremGenerator = new THREE.PMREMGenerator(renderer);
        loader.load(url, (texture) => {
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;
          texture.dispose();
          pmremGenerator.dispose();
          resolve(envMap);
        });
      });
    },

    enableCameraMovement() {
      isCameraMovementEnabled = true;
    },

    disableCameraMovement() {
      isCameraMovementEnabled = false;
    },
  },
});
