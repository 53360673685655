
import { defineComponent } from "vue";
export default defineComponent({
  name: "NavBar",
  mounted() {
    window.addEventListener("load", function () {
      navbarHandler();
    });
  },
});

function navbarHandler() {
  function moveShape(target: EventTarget) {
    let navShape = document.getElementById("nav-shape");

    let elementTarget = target as HTMLTextAreaElement;
    let linkWidth = elementTarget.offsetWidth;
    let linkOffset = elementTarget.offsetLeft;

    navShape!.style.width = linkWidth + "px";
    navShape!.style.left = linkOffset + "px";
  }

  let navLinks = document.querySelectorAll(".nav-link");
  let currentLink = document.getElementsByClassName("router-link-active")[0];
  if (currentLink) {
    moveShape(currentLink);
  }

  // Animation on click
  for (let link of navLinks) {
    link.addEventListener("click", (event) => {
      let navShapeBG = document.getElementById("nav-shape-bg");

      // disable all links
      for (link of navLinks) {
        link.classList.add("inactive-link");
      }

      // page-change animation
      navShapeBG!.style.width = "140%";
      setTimeout(() => {
        navShapeBG!.style.width = "100%";
      }, 100);
      moveShape(event.target!);
    });
  }

  function scaleNavbar() {
    let scale = window.innerWidth / 420;
    if (scale > 1) scale = 1;

    let floating_navbar = document.getElementById("floating-navbar");
    if (floating_navbar) floating_navbar.style.scale = "" + scale;
  }
  scaleNavbar();
  let floating_navbar = document.getElementById("floating-navbar");
  if (floating_navbar) floating_navbar.classList.add("loaded-navbar");
  window.addEventListener(
    "resize",
    function () {
      let currentLink =
        document.getElementsByClassName("router-link-active")[0];
      if (currentLink) {
        moveShape(currentLink);
      }
      scaleNavbar();
    },
    false
  );
}
