
import { defineComponent } from "vue";
import Motto from "@/components/Motto.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    Motto,
  },
  mounted() {
    document.title = "nervve | home";
    document.body.classList.add("no-vertical-scroll");
  },
  unmounted() {
    document.body.classList.remove("no-vertical-scroll");
  },
});
