
import { defineComponent } from "vue";

let isAboutVertical = false;
let isAboutMounted = false;
let isMobile = false;

let firstElementChild: HTMLElement;
let lastElementChild: HTMLElement;

export default defineComponent({
  name: "AboutView",
  data() {
    return {
      desktopSettings: {
        disappearingSpeed: 1.8,
        opacityFocusPoint: 30,
        textScaleFocusPoint: 40,
      },
      mobileSettings: {
        showingSpeed: 1,
      },
    };
  },
  mounted() {
    document.title = "nervve | about us";
    isAboutMounted = true;
    this.initPipeRoller();
  },
  deactivated() {
    console.log("deactivated");
  },
  unmounted() {
    isAboutMounted = false;
    document.body.classList.remove("mobile-about");
  },
  methods: {
    initPipeRoller() {
      const heightOnInit = window.innerHeight;
      // @ts-expect-error: TS is not aware of isMobile() method of parent during compilation
      if (this.$parent.$parent.isMobile()) isMobile = true;

      let about_element = document.getElementById("about-pipe-roller");
      if (!about_element) return;

      firstElementChild = about_element.firstElementChild as HTMLElement;
      lastElementChild = about_element.lastElementChild as HTMLElement;

      let nodes = document.querySelectorAll<HTMLElement>(".roller-element");
      const numberOfElements = nodes.length;

      // config loading
      const d_disappearingSpeed = this.desktopSettings.disappearingSpeed;
      const d_opacityFocusPoint = this.desktopSettings.opacityFocusPoint;
      const d_textScaleFocusPoint = this.desktopSettings.textScaleFocusPoint;
      const m_ShowingSpeed = this.mobileSettings.showingSpeed;

      function mobileChecker() {
        const ratio = window.innerWidth / window.innerHeight;
        if (ratio > 1.45) {
          isAboutVertical = false;
          document.body.classList.remove("mobile-about");
        } else {
          isAboutVertical = true;
          document.body.classList.add("mobile-about");
        }
      }
      mobileChecker();

      // Entry point
      window.addEventListener(
        "resize",
        function () {
          if (!isAboutMounted) return;
          mobileChecker();
          setRollerPositions();
        },
        false
      );

      function setRollerPositions() {
        if (!isAboutVertical) {
          rollerPositionsDesktop();
        } else {
          rollerPositionsMobile();
        }
      }

      function rollerPositionsDesktop() {
        for (var i = 0; i < numberOfElements; i++) {
          const scrollNormalized =
            document.documentElement.scrollTop /
            (document.body.offsetHeight - window.innerHeight);

          const spacer = 15;
          const startingPosition = 25;

          let distance =
            startingPosition -
            spacer * i +
            scrollNormalized * 12.5 * numberOfElements;
          if (distance < 0) distance = 0;

          // left = 0 is on left, left = 100vw is on right
          nodes[i].style.left =
            i % 2 == 0 ? 50 - distance + "vw" : 50 + distance + "vw";
          nodes[i].style.top = "50%";

          let textScale = distance / d_textScaleFocusPoint;
          textScale = textScale > 1 ? 1 : textScale;
          textScale = textScale < 0 ? 0 : textScale;

          nodes[i].style.transform =
            "translate(-50%, -50%) scale(" + textScale + ")";

          let opacity = distance / d_opacityFocusPoint;
          // Dissapearing if passed the point 1
          if (opacity > 1) opacity = 1 - (opacity - 1) * d_disappearingSpeed;

          nodes[i].style.opacity = "" + opacity;
        }
      }

      function rollerPositionsMobile() {
        let usedHeight: number;
        if (isMobile) {
          usedHeight = heightOnInit;
        } else {
          usedHeight = window.innerHeight;
        }

        if (!firstElementChild || !lastElementChild) return;
        const heightOfRoller =
          lastElementChild.offsetTop - firstElementChild.offsetTop;

        for (let i = 0; i < numberOfElements; i++) {
          const offsetOutsideViewport = document.body.offsetHeight - usedHeight;
          const scrollNormalized =
            document.documentElement.scrollTop / offsetOutsideViewport;

          const spacer = offsetOutsideViewport / numberOfElements;
          const startingPosition = usedHeight / 2.5;

          const startingPos = startingPosition + spacer * i;
          const movement = scrollNormalized * heightOfRoller + 20;
          let distance = startingPos - movement;

          nodes[i].style.left = "50%";
          nodes[i].style.top = distance + "px";

          let textScale = 1;

          nodes[i].style.transform =
            "translate(-50%, -50%) scale(" + textScale + ")";

          let opacity = distance / startingPosition;
          if (opacity > 1) opacity = 1 - (opacity - 1) * m_ShowingSpeed;

          nodes[i].style.opacity = "" + opacity;
        }
      }

      setRollerPositions();
      document.addEventListener("scroll", function () {
        setRollerPositions();
      });
    },
  },
});
