
import { defineComponent } from "vue";

export default defineComponent({
  name: "PortfolioView",
  mounted() {
    document.title = "nervve | portfolio";
    document.body.classList.add("no-vertical-scroll");
  },
  unmounted() {
    document.body.classList.remove("no-vertical-scroll");
  },
});
